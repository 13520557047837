import {FC, memo, useState} from "react";
import { Modal } from "react-bootstrap";
import {IInventoryItem} from "@interfaces/consumer/users";
import {useAdvanceTableContext} from "@components/falcon/common/tables/context";
import ConsumerInventoryService from "@api/consumer/consumerInventoryService";
import {useParams} from "react-router-dom";
import {toastr} from "react-redux-toastr";

interface DeleteItemModalProps {
    isOpened: boolean;
    toggleModal(): void;
    item: IInventoryItem;
}

const DeleteItemModal: FC<DeleteItemModalProps> = ({item: {id, itemName, count}, isOpened, toggleModal}) => {

    const [disabled, setDisabled] = useState(false);
    const { id: userId } = useParams<{ id: string }>();
    const {
        options: {meta}
    } = useAdvanceTableContext();
    const refreshData = (meta as any).refreshData;

    const deleteItem = async () => {

        console.log("delete clicked");
        setDisabled(true);

        const api = new ConsumerInventoryService();
        const result = await api.deleteInventoryItem(userId, id);
        if(result.ok) {
            refreshData(true);
            toastr.success("Инвентарь", `Предмет ${itemName} успешно удален`);
            toggleModal();
        }
        else {
            if(result.errorCode === "UserNotFound") {
                toastr.error("Инвентарь", "Пользователь не найден");
            }
            else if(result.errorCode === "ItemNotFound") {
                refreshData(true);
                toastr.error("Инвентарь", "Предмет не найден");
            }
            else {
                toastr.error("Инвентарь", "Не удалось удалить предмет");
            }
        }

        setDisabled(false);
    }

    return (
        <Modal
            show={isOpened}
            onHide={toggleModal}
            size="lg"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>Удалить предмет</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Вы уверены, что хотите удалить предмет {itemName} x{count}?</p>
            </Modal.Body>
            <Modal.Footer>
                <button
                    className="btn btn-outline-primary"
                    onClick={toggleModal}
                >
                    Отмена
                </button>
                <button
                    className="btn btn-primary"
                    onClick={deleteItem}
                    disabled={disabled}
                >
                    Удалить
                </button>
            </Modal.Footer>
        </Modal>
    )
}

export default memo(DeleteItemModal);