import { injectContextArray } from "@components/constructor";
import { useLoginServerContext } from "@components/constructor/components/loginServer/context";
import { LoginIdProps } from "@components/constructor/components/loginServer";
import { useGameServers } from "@hooks/selectors/game.selectors";
import { GameServer } from "@interfaces/game";
import {FC, PropsWithChildren, useMemo} from "react";
import { useParams } from "react-router-dom";
import { GameServerProvider } from "./context";
import {InjectContextType} from "@interfaces/constructor/constructor";

const GameServersList: FC<PropsWithChildren<LoginIdProps>> = ({ children, login_id }) => {

    const loginServer = useLoginServerContext();
    const { login_id: params } = useParams<{ login_id: string }>();
    const servers = useGameServers(loginServer?.id || params || login_id);

    const games: InjectContextType<GameServer>[] = useMemo(() => servers.map(gs => ({
        key: gs.id,
        value: gs,
        childData: {
            game_server_id: gs.id,
            game_server_name: gs.name
        }
    })), [servers]);

    return injectContextArray(games, GameServerProvider, children);
}

export default GameServersList;