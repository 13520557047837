import { ConstructionComponentInfo } from "@interfaces/constructor/constructor";
import Inventory from "./inventory";
import InventoryItem from "./inventoryItem";
import InventoryItemCount from "./inventoryItemCount";
import InventoryItemsList from "./inventoryItemsList";
import SendInventoryItemForm from "./sendInventoryItemForm";

const InventoryInfo: ConstructionComponentInfo = {
    component: Inventory,
    props: [
        'inventory_item_per_page'
    ],
    addedChildProps: [
        'pagination_current_page',
        'pagination_total_pages',
        'pagination_per_page',
        'pagination_set_page',
        'pagination_next_page',
        'pagination_prev_page',
        'inventory_item_count'
    ]
}

const InventoryItemInfo: ConstructionComponentInfo = {
    component: InventoryItem,
    requiredParent: [
        'InventoryItemsList'
    ]
}

const InventoryItemCountInfo: ConstructionComponentInfo = {
    component: InventoryItemCount,
    requiredParent: [
        'InventoryItemsList'
    ]
}

const InventoryItemsListInfo: ConstructionComponentInfo = {
    component: InventoryItemsList,
    props: [
        'default_server_id'
    ],
    addedChildProps: [
        'inventory_item_index',
        'inventory_item_id'
    ],
    injectChild: [
        'pagination_current_page',
        'pagination_per_page'
    ]
}

const SendInventoryItemFormInfo: ConstructionComponentInfo = {
    component: SendInventoryItemForm,
    injectChild: [
        'modalToggle'
    ]
}

export {
    InventoryInfo,
    InventoryItemInfo,
    InventoryItemCountInfo,
    InventoryItemsListInfo,
    SendInventoryItemFormInfo
}