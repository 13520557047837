import {FC, useCallback, useState} from "react";
import {useParams} from "react-router-dom";
import {ColumnDef} from "@tanstack/react-table";
import {IInventoryItem} from "@interfaces/consumer/users";
import {Flex, IconButton} from "@components/falcon/common";
import {dateFormatter} from "@services/helpers";
import {useRemoteTable} from "@hooks/tableHooks";
import {PanelTableDataModel} from "@models/panel";
import {Card, Dropdown} from "react-bootstrap";
import {AdvanceTable, AdvanceTableFooter, AdvanceTableSearchBox} from "@components/falcon/common/tables";
import AdvanceTableProvider from "@components/falcon/common/tables/context";
import InventoryItem from "./inventoryItem";
import {useRole} from "@services/hooks";
import {useSimpleModal} from "@hooks/panelHooks";
import AddItemModal from "./addItemModal";
import ConsumerInventoryService from "@api/consumer/consumerInventoryService";
import InventoryItemActions from "./inventoryItemActions";

const columns: ColumnDef<IInventoryItem>[] = [
    {
        accessorKey: 'itemName',
        header: 'Название',
        meta: {
            headerProps: { className: 'pe-1 text-900' },
            cellProps: {
                className: 'py-2'
            }
        },
        cell: ({row: {original}}) => (
            <InventoryItem item={original} />
        )
    },
    {
        accessorKey: 'count',
        header: 'Количество',
        enableSorting: true,
        meta: {
            headerProps: { className: 'text-900' }
        }
    },
    {
        accessorKey: 'updateTime',
        header: 'Дата изменения',
        enableSorting: true,
        cell: ({row: {original: {updateTime}}}) => dateFormatter(updateTime),
        meta: {
            headerProps: { className: 'text-900 text-end' },
            cellProps: {
                className: 'text-end'
            }
        }
    },
    {
        header: '',
        accessorKey: 'id',
        enableSorting: false,
        cell: ({row: {original}}) => (
            <InventoryItemActions item={original} />
        ),
        meta: {
            headerProps: { className: 'text-end', scope: 'col' },
            cellProps: {
                className: 'text-end'
            }
        }
    }
]

const InventoryList: FC = () => {

    const { id } = useParams<{ id: string }>();
    const [api] = useState(() => new ConsumerInventoryService());
    const fetchApi = useCallback((model: PanelTableDataModel) =>
        api.fetchInventoryList(id, model), [api, id]);
    const canEdit = useRole('consumer.users.inventory.edit');

    const table = useRemoteTable({
        name: 'consumer.users.items.list',
        columns,
        defaultState: {
            sorting: [
                {
                    id: 'itemName',
                    desc: true
                }
            ]
        }
    }, fetchApi);

    const {
        isOpened,
        toggleModal,
        setOpened
    } = useSimpleModal();

    return (
        <AdvanceTableProvider table={table}>
            <Card className="mb-3">
                <Card.Header>
                    <Flex className='align-items-center justify-content-between'>
                        <Flex alignItems="center" className="fs-10">
                            <h5 className="fs-9 mb-0 text-nowrap py-2 py-xl-0">Инвентарь</h5>
                        </Flex>
                        {canEdit ? (
                            <Flex>
                                <IconButton
                                    variant="falcon-default"
                                    size="sm"
                                    icon="plus"
                                    transform="shrink-3"
                                    className='me-2'
                                    onClick={() => setOpened(true)}
                                />
                                <Flex>
                                    <AdvanceTableSearchBox/>
                                </Flex>
                            </Flex>
                        ) : (
                            <Flex>
                                <AdvanceTableSearchBox/>
                            </Flex>
                        )}

                    </Flex>
                </Card.Header>
                <Card.Body className="p-0">
                    <AdvanceTable
                        headerClassName="bg-200 text-nowrap align-middle"
                        rowClassName="align-middle white-space-nowrap btn-reveal-trigger"
                        tableProps={{
                            size: 'sm',
                            striped: true,
                            className: 'fs-10 mb-0 overflow-hidden'
                        }}
                    />
                </Card.Body>
                <Card.Footer>
                    <AdvanceTableFooter
                        rowInfo
                        rowsPerPageSelection
                        paginationButtons
                    />
                </Card.Footer>
            </Card>
            {canEdit && (
                <AddItemModal
                    isOpen={isOpened}
                    toggle={toggleModal}
                />
            )}
        </AdvanceTableProvider>
    )
}

export default InventoryList;