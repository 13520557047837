import {FC, PropsWithChildren, useMemo} from "react";
import { injectContextArray } from "@components/constructor";
import { useInventory } from "@hooks/selectors/user.selectors";
import { IInventoryItem } from "@interfaces/user/items";
import { PaginationProps } from "@constructor/components/core/pagination";
import { InventoryItemProvider } from "./context";
import { useItems } from "@hooks/selectors/user/item.selectors";
import {InjectContextType} from "@interfaces/constructor/constructor";
import {useGlobalVariable} from "@hooks/selectors/panel.selectors";
import {useParams} from "react-router-dom";

interface InventoryItemsListChildProps {
    inventory_item_index: number;
    inventory_item_id: string;
}

interface InventoryItemsListProps {
    default_server_id?: string;
}

const InventoryItemsList: FC<PropsWithChildren<PaginationProps & InventoryItemsListProps>> =
    ({ default_server_id, pagination_current_page = 0, pagination_per_page = 10, children }) => {

    const items = useInventory();

    const itemIds = items.map(i => i.itemId);
    const globalServerId = useGlobalVariable('inventory_server_id')?.toString();
    const {server_id: paramServerId } = useParams<{server_id?: string}>();

    const serverId = paramServerId || globalServerId || default_server_id;

    useItems(itemIds);

    const page = useMemo(() =>
            items.filter(x => !serverId || x.servers.length === 0 || x.servers.includes(serverId))
                .slice(pagination_current_page * pagination_per_page, (pagination_current_page + 1) * pagination_per_page),
        [items, pagination_current_page, pagination_per_page, serverId]);

    const data = useMemo<InjectContextType<IInventoryItem, InventoryItemsListChildProps>[]>(() =>
        page.map((item, idx) => ({
            key: item.id,
            value: item,
            childData: {
                inventory_item_index: pagination_current_page * pagination_per_page + 1 + idx,
                inventory_item_id: item.id
            }
        })), [page, pagination_current_page, pagination_per_page]);

    return injectContextArray(data, InventoryItemProvider, children);
}

export default InventoryItemsList;