import React, {FC, memo} from "react";
import {Field, Form as FormikForm, Formik} from "formik";
import {Button, Col, Form, Modal, Row} from "react-bootstrap";
import {FormikRowInput} from "@components/panel/formik";
import {FormikItemInput} from "@components/consumer/helpers";
import ConsumerInventoryService from "@api/consumer/consumerInventoryService";
import {useParams} from "react-router-dom";
import {toastr} from "react-redux-toastr";
import * as Yup from "yup";
import {useAdvanceTableContext} from "@components/falcon/common/tables/context";

interface AddItemModalProps {
    isOpen: boolean;
    toggle(): void;
}

interface AddItemFormValues {
    itemId: string;
    count: number;
}

const AddItemModal: FC<AddItemModalProps> = ({isOpen, toggle}) => {

    const { id } = useParams<{ id: string }>();

    const initialValues: AddItemFormValues = {
        itemId: '',
        count: 1
    }

    const {
        options: {meta}
    } = useAdvanceTableContext();
    const refreshData = (meta as any).refreshData;

    const validationSchema = Yup.object().shape({
        itemId: Yup.string()
            .required('Предмет обязателен'),
        count: Yup.number()
            .required('Количество обязательно')
            .min(1, 'Количество должно быть больше 0')
    });

    const onSubmit = async (values: AddItemFormValues) => {
        const api = new ConsumerInventoryService();
        const result = await api.addInventoryItem({
            itemId: values.itemId,
            count: values.count,
            userId: id
        });

        if (result.success) {
            refreshData(true);
            toastr.success("Инвентарь", `Предмет ${result.data.itemName} успешно добавлен`);
            toggle();
        }
        else {
            if(result.errorCode === "UserNotFound") {
                toastr.error("Инвентарь", "Пользователь не найден");
            }
            else if(result.errorCode === "ItemNotFound") {
                toastr.error("Инвентарь", "Предмет не найден");
            }
            else {
                toastr.error("Инвентарь", "Не удалось добавить предмет");
            }
        }
    }

    return (
        <Modal
            show={isOpen}
            onHide={toggle}
            size="lg"
            centered
        >
            <Modal.Header>
                Добавить предмет
            </Modal.Header>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                <FormikForm>
                    <Modal.Body>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label
                                column
                                sm={3}
                            >
                                Предмет
                            </Form.Label>
                            <Col sm={9}>
                                <Field
                                    name='itemId'
                                    component={FormikItemInput}
                                />
                            </Col>
                        </Form.Group>
                        <Field
                            label='Количество'
                            name='count'
                            type='number'
                            component={FormikRowInput}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button color="secondary" onClick={toggle}>
                            Закрыть
                        </Button>
                        <Button color="primary" type="submit">
                            Добавить
                        </Button>
                    </Modal.Footer>
                </FormikForm>
            </Formik>
        </Modal>
    )
}

export default memo(AddItemModal);