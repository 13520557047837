import { PanelTableDataModel } from "@models/panel";
import { FC, useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { Card } from "react-bootstrap";
import { IInventoryLog } from "@interfaces/consumer/users";
import { dateFormatter } from "@services/helpers";
import {ItemName} from "@components/consumer/helpers";
import {ColumnDef} from "@tanstack/react-table";
import {useRemoteTable} from "@hooks/tableHooks";
import {Flex} from "@components/falcon/common";
import {AdvanceTable, AdvanceTableFooter} from "@components/falcon/common/tables";
import AdvanceTableProvider from "@components/falcon/common/tables/context";
import ConsumerInventoryService from "@api/consumer/consumerInventoryService";

const columns: ColumnDef<IInventoryLog>[] = [
    {
        accessorKey: 'itemId',
        header: 'Название',
        enableSorting: false,
        cell: ({row: {original: {itemId}}}) => (
            <ItemName itemId={itemId} />
        ),
        meta: {
            headerProps: { className: 'pe-1 text-900' },
            cellProps: { className: 'py-2' },
            placeholderSize: [4, 8]
        }
    },
    {
        accessorKey: 'count',
        header: 'Количество',
        enableSorting: true,
        meta: {
            headerProps: { className: 'text-900' }
        }
    },
    {
        accessorKey: 'date',
        header: 'Дата',
        enableSorting: true,
        cell: ({row: {original: {date}}}) => dateFormatter(date),
        meta: {
            headerProps: { className: 'text-900 text-end' },
            cellProps: {
                className: 'text-end'
            }
        }
    }

]

const LogsList: FC = () => {

    const { id } = useParams<{ id: string }>();
    const [api] = useState(() => new ConsumerInventoryService());
    const fetchApi = useCallback((model: PanelTableDataModel) =>
        api.fetchInventoryLogs(id, model), [api, id]);

    const table = useRemoteTable({
        name: 'consumer.users.items.logs',
        columns,
        defaultState: {
            sorting: [
                {
                    id: 'date',
                    desc: true
                }
            ]
        }
    }, fetchApi);

    return (
        <AdvanceTableProvider table={table}>
            <Card className="mb-3">
                <Card.Header>
                    <Flex className='align-items-center justify-content-between'>
                        <Flex alignItems="center" className="fs-10">
                            <h5 className="fs-9 mb-0 text-nowrap py-2 py-xl-0">История инвентаря</h5>
                        </Flex>
                        {/*<Flex>
                            <AdvanceTableSearchBox/>
                        </Flex>*/}
                    </Flex>
                </Card.Header>
                <Card.Body className="p-0">
                    <AdvanceTable
                        headerClassName="bg-200 text-nowrap align-middle"
                        rowClassName="align-middle white-space-nowrap"
                        tableProps={{
                            size: 'sm',
                            striped: true,
                            className: 'fs-10 mb-0 overflow-hidden'
                        }}
                    />
                </Card.Body>
                <Card.Footer>
                    <AdvanceTableFooter
                        rowsPerPageSelection
                        paginationButtons
                    />
                </Card.Footer>
            </Card>
        </AdvanceTableProvider>
    )
}

export default LogsList;