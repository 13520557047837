import { IInventoryItem } from "@interfaces/consumer/users";
import { FC, memo } from "react";
import { Link } from "react-router-dom";
import {Avatar, Flex} from "@components/falcon/common";

interface InventoryItemProps {
    item: IInventoryItem;
}

const InventoryItem : FC<InventoryItemProps> = ({item: {itemId, itemName, iconId}}) => (
    <Link to={`/consumer/item/${itemId}/`}>
        <Flex alignItems="center">
            <Avatar fileId={iconId} name={itemName} size="xl" className="me-2"/>
            <div className="flex-1">
                <h5 className="mb-0 fs-10">{itemName}</h5>
            </div>
        </Flex>
    </Link>
)

export default memo(InventoryItem);