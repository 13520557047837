import {FC, memo} from "react";
import {Dropdown} from "react-bootstrap";
import {IconButton} from "@components/falcon/common";
import {IInventoryItem} from "@interfaces/consumer/users";
import DeleteItemModal from "./deleteItemModal";
import {useRole} from "@services/hooks";
import {toastr} from "react-redux-toastr";
import {useSimpleModal} from "@hooks/panelHooks";

interface InventoryItemActionsProps {
    item: IInventoryItem;
}

const InventoryItemActions: FC<InventoryItemActionsProps> = ({item}) => {

    const canEdit = useRole('consumer.users.inventory.edit');

    const lol = () => toastr.warning("Редактирование предмета", "Странно, но ничего не произошло 🤔🤔🤔");

    const {
        isOpened,
        toggleModal,
        openModal
    } = useSimpleModal();

    return (
        <>
            <Dropdown className="font-sans-serif position-static">
                <Dropdown.Toggle as={IconButton} icon="ellipsis-h" variant="link" className="text-600 btn-sm btn-reveal" />
                <Dropdown.Menu className="dropdown-menu-end border py-0">
                    <div className="py-2">
                        <Dropdown.Item onClick={lol}>История</Dropdown.Item>
                        {canEdit && (<>
                            <Dropdown.Item onClick={lol}>Изменить</Dropdown.Item>
                            <Dropdown.Item onClick={openModal}>Удалить</Dropdown.Item>
                        </>)}
                    </div>
                </Dropdown.Menu>
            </Dropdown>
            {canEdit && (
                <DeleteItemModal
                    isOpened={isOpened}
                    toggleModal={toggleModal}
                    item={item}
                />
            )}
        </>
    )
}

export default memo(InventoryItemActions);