import IService from "@api/iService";
import {IInventoryItem, IInventoryLog} from "@interfaces/consumer/users";
import {PanelTableDataModel} from "@models/panel";
import {PanelTableData} from "@interfaces/panel";
import {PutInventoryItemModel} from "@models/consumer/items";

export default class ConsumerInventoryService extends IService {
    fetchInventory = async (userId: string) => {
        return await this.getApi<IInventoryItem[]>(`/consumer/inventory/${userId}/`);
    }

    fetchInventoryList = async (userId: string, model: PanelTableDataModel) => {
        return await this.postApi<PanelTableData<IInventoryItem[]>>(`/consumer/inventory/${userId}/`, model);
    }

    fetchInventoryLogs = async (userId: string, model: PanelTableDataModel) => {
        return await this.postApi<PanelTableData<IInventoryLog[]>>(`/consumer/logs/item/${userId}/`, model);
    }

    addInventoryItem = async (model: PutInventoryItemModel) => {
        return await this.putApi<IInventoryItem>(`/consumer/inventory/`, model);
    }

    deleteInventoryItem = async (userId: string, itemId: string) => {
        return await this.deleteApi(`/consumer/inventory/${userId}/${itemId}/`);
    }
}