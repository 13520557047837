import {
    ConsumerUser,
    ConsumerUserAccount, IUserSecurityLog,
    ConsumerUserBalanceLog,
    ConsumerUserDonateLog,
    ConsumerUserFull,
    ConsumerUserLogs,
    IInventoryItem,
    IInventoryLog, IUserTotalDonate
} from '@interfaces/consumer/users';
import { KeyValueSet } from '@interfaces/helpers';
import { PanelTableData } from '@interfaces/panel';
import { ConsumerUserChangeBalanceModel, ConsumerUserChangeRolesModel, ConsumerUserChargeAccountModel } from '@models/consumer/users';
import { PanelTableDataModel } from '@models/panel';
import IService from '../iService';
import {ConsumerPaymentShort} from "@interfaces/consumer/payments";

export default class ConsumerUsersService extends IService {
    async changeConsumerUserBalance(balance: ConsumerUserChangeBalanceModel) {
        return await this.postApi('/consumer/users/balance/', balance);
    }

    async changeConsumerUserRoles(roles: ConsumerUserChangeRolesModel) {
        return await this.patchApi('/consumer/users/roles/', roles);
    }

    async chargeConsumerUserAccount(model: ConsumerUserChargeAccountModel) {
        return await this.postApi('/consumer/users/charge/', model);
    }

    fetchConsumerUsers = async (model: PanelTableDataModel) => {
        return await this.postApi<PanelTableData<ConsumerUser[]>>(`/consumer/users/`, model);
    }

    async fetchUser(id: string): Promise<ConsumerUserFull> {
        return await this.getAndThrowApi<ConsumerUserFull>(`/consumer/users/${id}/`);
    }

    async fetchUserAccounts(id: string) {
        return await this.getAndThrowApi<ConsumerUserAccount[]>(`/consumer/users/${id}/accounts/`)
    }

    async fetchUserPayments(id: string) {
        return await this.getAndThrowApi<ConsumerUserDonateLog[]>(`/consumer/users/${id}/payments/`)
    }

    async fetchUserLogs(id: string) {
        return await this.getAndThrowApi<ConsumerUserLogs>(`/consumer/users/${id}/logs/`)
    }

    async fetchConsumerRoles() {
        return await this.getAndThrowApi<KeyValueSet<string[]>>('/consumer/users/roles/');
    }

    fetchBalanceLogs = async (userId: string, model: PanelTableDataModel) => {
        return await this.postApi<PanelTableData<ConsumerUserBalanceLog[]>>(`/consumer/users/${userId}/logs/balance/`, model);
    }

    fetchPaymentLogs = async (userId: string, model: PanelTableDataModel) => {
        return await this.postApi<PanelTableData<ConsumerPaymentShort[]>>(`/consumer/users/${userId}/payments/`, model);
    }

    fetchSecurityLogs = async (userId: string, model: PanelTableDataModel) => {
        return await this.postApi<PanelTableData<IUserSecurityLog[]>>(`/consumer/users/${userId}/logs/security/`, model);
    }

    fetchUserTotalDonate = async (userId: string) => {
        return await this.getAndThrowApi<IUserTotalDonate>(`/consumer/users/${userId}/payments/total/`);
    }
}